.accordian {
  details {
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    border-radius: 0px;
    padding-bottom: 0px;
    background: #f5f0f0 !important;
  }
  details[open] > summary {
    padding-top: 20px;
    padding-bottom: 10px;
    @media (max-width: 768px) {
      padding-top: 10px !important;
    }
  }
  summary {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  details:hover {
    background: #d1e9ef !important;
  }

  details[open] {
    background: #d1e9ef !important;
    padding-bottom: 20px;
    .accord-arrows::before {
      @media (max-width: 768px) {
        padding-bottom: 10px !important;
      }
      content: "-";
      padding-top: 10px;
      color: #3d3d3c !important;
      font-size: 30px;
      position: absolute;
      top: 0;
      right: 0;
    }
    -webkit-appearance: none;
  }

  .accord-arrows::before {
    content: "+";
    padding-top: 20px;
    font-size: 30px;
    position: absolute;
    right: 0;
    color: #3d3d3c !important;
    top: 0;
    @media (max-width: 768px) {
      padding-bottom: 10px !important;
    }
  }

  details > summary {
    list-style: none;
  }

  details > summary::-webkit-details-marker {
    display: none;
  }
}

.project-accordian {
  details {
    cursor: pointer;
    border-radius: 20px;
    padding: 20px 30px;
    @media (max-width: 768px) {
      padding: 10px 20px;
    }
    h3 {
      font-weight: 200;
    }
  }
  .acc-arrow {
    position: absolute;
    right: 30px;
    top: 10px;
    @media (max-width: 768px) {
      right: 0;
      top: 0;
    }
  }
  details[open] {
    .acc-arrow {
      transform: rotate(180deg);
      #Ellipse_66 {
        fill: #00a5b6;
      }
    }
  }
  details[open] > summary {
    padding-top: 20px;
    padding-bottom: 0px;
    @media (max-width: 768px) {
      padding-top: 10px !important;
    }
  }
  summary {
    padding-top: 20px;
    padding-bottom: 0px;
    @media (max-width: 768px) {
      padding-top: 10px;
    }
  }
  .accord-arrows {
    margin-right: 10px;
    margin-bottom: 0px !important;
  }

  details[open] {
    padding-bottom: 0px;
    .accord-arrows::before {
      @media (max-width: 768px) {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
      }
      content: "-";
      padding-top: 10px;
      margin-bottom: 0px;
      line-height: 21px;
      color: #3d3d3c !important;
      font-size: 21px;
    }
    -webkit-appearance: none;
  }

  .accord-arrows::before {
    content: "+";
    line-height: 21px;
    font-size: 21px;
    margin-bottom: 0px;
    color: #3d3d3c !important;
    @media (max-width: 768px) {
      padding-top: 0px !important;
      padding-bottom: 10px !important;
    }
  }

  details > summary {
    list-style: none;
  }

  details > summary::-webkit-details-marker {
    display: none;
  }
}

.accordian-nav {
  details {
    cursor: pointer;
    border-radius: 0px;
    padding-bottom: 0px;
  }
  details[open] > summary {
    font-weight: 600 !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  summary:hover {
    font-weight: 600 !important;
  }
  summary {
    line-height: 33px !important;
  }

  details[open] {
    padding-bottom: 10px;
    .accord-arrows::before {
      content: "-";
      color: #fff;
    }
    .accord-arrows:hover::before {
      content: "-";
      color: #00a5b6;
    }
    -webkit-appearance: none;
  }

  .accord-arrows:hover::before {
    content: "+";
    color: #00a5b6;
  }

  .accord-arrows::before {
    content: "+";
    color: #fff;
  }

  details > summary {
    list-style: none;
  }

  details > summary::-webkit-details-marker {
    display: none;
  }
}

.hover-anim {
  background: linear-gradient(#f5f0f0 0 0) var(--p, 0) / var(--p, 0) no-repeat;
  transition: 0.6s, background-position 1s;
}

.hover-anim:hover {
  --p: 100%;
  color: #3d3d3c;
}
