/*! Flickity v2.3.0
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: #f5f0f0;
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: #c4c1c1;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #fff;
}

.flickity-button:active {
  opacity: 1;
}

.flickity-button:disabled {
  opacity: 0;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.sus-slide {
  .flickity-prev-next-button.next {
    right: -50px;
    @media (max-width: 1024px) {
      right: 10px;
    }
    @media (max-width: 760px) {
      right: 0px;
    }
  }
  .flickity-prev-next-button.previous {
    display: none;
  }
}

.sus-inner-slide.is-selected {
  opacity: 1;
}
.sus-inner-slide {
  opacity: 0.4;
}

.flickity-prev-next-button.previous {
  left: -50px;
  @media (max-width: 760px) {
    left: 0px;
  }
}
.flickity-prev-next-button.next {
  right: -50px;
  @media (max-width: 760px) {
    right: 0px;
  }
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block !important;
  width: 10px !important;
  height: 10px !important;
  margin: 0 8px !important;
  background: #333 !important;
  border-radius: 50% !important;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.image-dots {
  background: #d1e9ef;
}
.image-dots .active {
  background: #002e42;
}
