.headroom--unpinned {
  .asx-head {
    display: none;
  }
  .mt-7 {
    margin-top: 0.5rem !important;
  }
  .lang-btn {
    top: 0;
  }
}
.headroom--unpinned,
.headroom--pinned,
.headroom {
  .nav-inner-bg {
  }
  #signup {
    display: block !important;
  }
  // margin-top: 20px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-duration: 250ms;
  filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.2));
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
  transition-duration: 200ms !important;
  @media (max-width: 768px) {
    margin-top: 0;
  }
  .nav-inner-light {
    @media (max-width: 768px) {
      margin-top: 0px;
      padding-left: 20px;
      padding-right: 20px;
      height: 70px;
    }
    // margin-top: 20px;
    background-color: #002e42;
    padding-left: 40px;
    padding-right: 40px;
    height: 100px;
  }
  .nav-inner-dark {
    @media (max-width: 768px) {
      margin-top: 0px;
      padding-left: 20px;
      padding-right: 20px;
      height: 70px;
    }
    // margin-top: 20px;
    background-color: #f5f0f0;
    padding-left: 40px;
    padding-right: 40px;
    height: 80px;
    // iframe {
    //   filter: brightness(0.3);
    // }
  }
}

.headroom--top {
  #signup {
    display: none !important;
  }
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
  transition-duration: 200ms !important;
  box-shadow: none;
  display: grid;
  @media (max-width: 768px) {
    margin-top: 0;
  }
  .nav-inner-light {
    @media (max-width: 768px) {
      padding-left: 20px;
      padding-right: 20px;
      height: 70px;
      margin-top: 0px;
    }
    background-color: #2c2c2c00;
    padding-left: 40px;
    padding-right: 40px;
    height: 80px;
  }
  .nav-inner-dark {
    @media (max-width: 768px) {
      margin-top: 0px;
      padding-left: 20px;
      padding-right: 20px;
      height: 70px;
    }

    background-color: #ffffff00;
    padding-left: 40px;
    padding-right: 40px;
    height: 80px;
  }
}
