html,
body {
  font-family: "AlbertSans", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu,
    Cantarell, Noto Sans, sans-serif, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #3d3d3c;
  font-weight: 300;
}

input {
  outline: none !important;
}

.text-bold {
  font-weight: 600;
}

.wrapper {
  padding-left: 144px !important;
  padding-right: 144px !important;
  max-width: 1680px !important;
  margin: auto !important;
  @media (max-width: 1536px) {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  @media (max-width: 1280px) {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  @media (max-width: 768px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.mob-object-right {
  @media (max-width: 768px) {
    object-position: 65% !important;
  }
}
@mixin letter-spacing($spacing) {
  letter-spacing: calc(-1em * 0.001 * #{$spacing});
}

.heading-light {
  font-size: responsive 30px 45px !important;
  line-height: responsive 40px 60px !important;
  font-family: "Poppins-Light", sans-serif !important;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
.a {
  text-decoration: none !important;
}

h1,
.h1 {
  font-size: responsive 35px 75px !important;
  line-height: responsive 40px 80px !important;
  font-weight: 200;
}

.main-heading {
  h1,
  .h1 {
    margin-bottom: 20px;
    font-size: responsive 29px 75px !important;
    line-height: responsive 40px 80px !important;
    font-weight: 200;
  }
}

h2,
.h2 {
  margin-bottom: 20px;
  font-size: responsive 20px 35px;
  line-height: responsive 23px 40px;
  font-weight: 200;
}
.project-title {
  margin-bottom: 20px;
  font-size: responsive 29px 50px !important;
  line-height: responsive 40px 65px !important;
  font-weight: 200;
}
.links-title {
  margin-bottom: 20px;
  font-size: responsive 29px 67px !important;
  line-height: responsive 40px 73px !important;
  font-weight: 200;
}
.project-heading {
  margin-bottom: 20px;
  font-size: responsive 29px 60px !important;
  line-height: responsive 40px 70px !important;
  font-weight: 200;
}
h3,
.h3 {
  margin-bottom: 20px;
  font-size: responsive 17px 25px;
  line-height: responsive 20px 28px;
}

h4,
.h4 {
  margin-bottom: responsive 18px 20px;
  font-size: responsive 17px 25px;
  line-height: responsive 22px 30px;
  margin-bottom: 20px;
}

h5,
.h5 {
  margin-bottom: 20px;
  font-size: 13px;
  @include letter-spacing(-0.3);
}

h6,
.h6 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}
.text-green {
  color: #78be20;
}
.text-blue {
  color: #00a8ba;
}

.fill-blue:hover {
  background-color: #00a8ba;
}

.text-teal {
  color: #007672;
}
.border-green {
  border-color: #78be20;
}
.fill-teal:hover {
  background-color: #002e42;
}

.fill-green:hover {
  background-color: #78be20;
}

.bg-red {
  background-color: #ff1f44;
}

.bg-grey {
  background-color: #f5f0f0;
}

.bg-grey2 {
  background-color: #f4f4f4;
}

.bg-text {
  background-color: #3d3d3c;
}

.fill-black:hover {
  background-color: #1f1f1f;
}

.border-teal {
  border-color: #00a8ba;
}

p,
.p {
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
  margin-bottom: 20px;
  // font-weight: 200;
  font-size: responsive 15px 16px;
  line-height: responsive 20px 24px;
  // font-family: "work-sans", sans-serif;
  text-underline-offset: 10px;
  // transition: all !important;
  // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  // transition-duration: 150ms !important;
  // transition-duration: 200ms !important;
}

p:hover {
  text-underline-offset: 7px;
  // transition: all !important;
  // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}
.border-teal {
  border-color: #00a8ba;
}
p,
.p {
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
  margin-bottom: 20px;
  // font-weight: 200;
  font-size: responsive 15px 16px;
  line-height: responsive 20px 24px;
  // font-family: "work-sans", sans-serif;
  text-underline-offset: 10px;
  // transition: all !important;
  // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  // transition-duration: 150ms !important;
  // transition-duration: 200ms !important;
}

p:hover {
  text-underline-offset: 7px;
  // transition: all !important;
  // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  // transition-duration: 150ms !important;
  // transition-duration: 200ms !important;
}

a {
  p {
    font-family: "AlbertSans", sans-serif !important;
  }
}

.table-head {
  p {
    margin-bottom: 0px !important;
  }
  p strong {
    text-transform: uppercase !important;
    letter-spacing: 3px !important;
    margin-bottom: 0 !important;
    font-size: 14px !important;
    font-family: "AlbertSans-Bold", sans-serif !important;
  }
}

.intro-text-heading {
  color: #ffe000;
  font-weight: bold !important;

  strong {
    color: #c5e3d3 !important;
    font-weight: bold !important;
  }
}

.title-thin {
  font-family: "Poppins-Light", sans-serif !important;
}

.animation-pr {
  padding-right: 250px !important;
  margin-top: 975px !important;
  @media (max-width: 1400px) {
    padding-right: 100px !important;
    margin-top: 1000px !important;
  }
}

.nocursor {
  cursor: none !important;
}

@media (hover: hover) and (pointer: fine) {
  #cursor {
    z-index: 99;
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    will-change: transform;
  }

  #cursor .cursor--inner {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background: #ffe000;
  }
}

.privacy-dots {
  ul {
    margin-top: 20px;
  }
  li {
    text-align: center !important;
    @media (max-width: 760px) {
      text-align: left !important;
    }
    list-style: circle !important;
    margin-left: 40px;
  }
  // h3 {
  //   margin-bottom: 10px;
  // }
}

.privacy-dots2 {
  ul {
    margin-top: 10px;
  }
  li {
    list-style: circle !important;
    margin-left: 40px;
  }
  img {
    margin-top: 10px;
    margin-bottom: 20px;
    @media (max-width: 720px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.mega-menu {
  .drop {
    transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
    transform-origin: 50% 0%;
    opacity: 0;
    pointer-events: none;
    transform: scaleY(0.5) scaleX(0.8);
  }

  &:hover .drop {
    transition-delay: 0.25s;
    opacity: 1;
    pointer-events: all;
    transform: scaleY(1) scaleX(1);
  }
}

.mega-dots {
  li,
  .mega-dot {
    @apply text-text transition-all;
  }

  li:hover {
    @apply text-blue;
    .mega-dot {
      @apply bg-blue;
    }
  }
}

.about-nav,
.investors-nav,
.projects-nav,
.sustainability-nav {
  display: none;
}

.large-text {
  font-size: responsive 30px 60px !important;
  line-height: responsive 40px 65px !important;
  font-family: "AlbertSans-ExtraLight", sans-serif !important;
}

.category.active {
  border-bottom: 2px solid #3d3d3c !important;
}

.img-dots {
  background-color: #002e42;
}

.img-dots.active {
  background-color: #c5e3d3;
}

.proj-text {
  p {
    width: 80%;
    margin: auto;
  }
}
.pin-tooltip {
  pointer-events: none;
  height: 0;
}
.pin {
  &-tooltip {
    opacity: 0;
  }
  &:hover {
    .pin-tooltip {
      opacity: 1;
      pointer-events: all;
      height: auto;
    }
  }
}
.left-minus {
  left: -190px;
}
.togglebtn.active {
  background-color: #007672;
  color: white;
}
.h-max {
  height: max-content !important;
}

.centered-text {
  p {
    text-align: center;
    a {
      color: #00a5b6;
      font-weight: 900;
    }
    a:hover {
      color: #002e42;
      font-weight: 900;
    }
  }
  li {
    a {
      text-align: left !important;
      color: #00a4b6da;
      font-weight: 900;
    }
    a:hover {
      color: #00a5b6;
      font-weight: 900;
    }
  }
}

.table-cont {
  table {
    width: 100%;
    thead {
      tr {
        border-bottom: 1px solid #d8d8d8;
        td {
          min-width: 100px !important;
          padding: 15px 0;
          text-align: center;
        }
        td:first-child {
          text-align: left;
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #d8d8d8;
        td {
          @media (max-width: 900px) {
            min-width: 100px !important;
          }
          padding: 15px 0;
          text-align: center;
        }
        td:first-child {
          text-align: left;
        }
      }
    }
  }
}

.wysiwyg {
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin-bottom: 1.1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  th,
  td {
    text-align: left;
    padding-bottom: 0.75em;
    padding-right: 2em;
    vertical-align: top;
    min-width: 200px;
    white-space: nowrap;
    &:last-child {
      padding-right: 0;
    }
  }
  table {
    @extend .text-xs;
    width: 100%;
  }

  & > article {
    @media (max-width: 600px) {
      padding-bottom: 80px;
      margin-bottom: 80px;
    }

    padding-bottom: 130px;
    margin-bottom: 130px;
    border-bottom: 1px solid #313131;
  }
}
.text-xs {
  font-size: 13px;
  @include letter-spacing(-0.3);
  p {
    margin-bottom: 13px;
    font-size: 13px;
    @include letter-spacing(-0.3);
    line-height: 18px;
  }
}

.table-wrap {
  overflow-x: auto;
  max-width: 100%;
  margin: 40px 0;
  &:last-child {
    margin-bottom: 0;
  }
}

[data-article-nav] {
  text-align: left;
  max-width: 200px;
  a {
    text-decoration: none;
    display: block;
    padding: 3px 0;
    transition: all 0.2s ease-out;
    position: relative;
    font-size: 16px;
  }

  a.active,
  a:hover {
    padding: 10px 0;
    font-weight: 900;
    font-size: 18px;
    color: #007672;
  }
}

[data-article] {
  scroll-margin-top: 100px;
}

#header-video {
  object-fit: cover !important;
  height: 100% !important;
  .plyr__controls {
    display: none !important;
  }
}

.prevent-svg {
  tspan {
    font-weight: 600 !important;
  }
}

.image-text-justified {
  text-align: center;
  @media (min-width: 1024px) {
    text-align: left !important;
  }
  p {
    @media (min-width: 1024px) {
      text-align: justify;
    }
  }
}

.no-mb {
  p {
    margin-bottom: 0px;
  }
}

.iframe-image {
  .plyr__video-wrapper {
    iframe {
      src: url("/web/favicon.ico") !important;
    }
  }
}

.globe-block {
  @media (min-width: 1570px) {
    height: 850px !important;
  }
  @media (min-width: 1860px) {
    height: 950px !important;
  }
  @media (min-width: 2070px) {
    height: 1050px !important;
  }
}

.text-link {
  p {
    a {
      color: #00a5b6;
      font-weight: bold;
    }
  }
  ul {
    padding-left: 15px;
    li {
      list-style: initial;
    }
  }
}

.hide {
  transform: translateY(-1000em);
  position: absolute;
}

.show {
  transform: none;
  position: relative;
}

.proj-select select {
  background: transparent;
  -webkit-appearance: none;
}

.project-intro {
  p {
    font-size: 20px;
    line-height: 32px;
  }
}
.hide-desktop {
  display: none;
}
.links-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  .div1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .div2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .div3 {
    grid-area: 1 / 3 / 2 / 4;
  }
  .div4 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .div5 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .div6 {
    grid-area: 2 / 3 / 3 / 4;
  }
}
.opacity-bg {
  background: #ecf2f5;
}
.simpleParallax {
  height: 100%;
}
.gallery-cell:focus {
  outline: none !important;
}
.main-carousel {
  .flickity-slider {
    transform: none !important;
  }
  .flickity-page-dots {
    display: none;
  }
  .flickity-button {
    display: none;
  }
}
.project-thumb {
  position: relative;
  height: 100%;
  &::before {
    content: "View project";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 2;
    opacity: 0;
    border-bottom: 2px solid white;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #2a231f;
    opacity: 0.7;
    transform: translateY(-100%);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
}
.project-thumb:hover {
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #2a231f;
    opacity: 0.7;
    transform: translateY(0%);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  &::before {
    opacity: 1;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
}
.links-grid {
  background-color: #002e42;
}
.links-grid:hover {
  background-color: transparent;
}
.hoverable.active {
  background-color: #002e42;
}
.gallery-cell {
  left: 0 !important;
  opacity: 0.2;
  transition: opacity 0.5s ease-in-out;
  z-index: -1;
  transform: none !important;
}

.gallery-cell.is-selected {
  opacity: 1;
  z-index: 0;
  transition: opacity 0.5s ease-in-out;
}
.image-text-desc {
  p {
    font-size: responsive 16px 20px;
    line-height: responsive 20px 24px;
  }
}
.heading-p p {
  font-size: responsive 16px 20px;
  line-height: responsive 20px 22px;
}
.heading-text-desc {
  position: relative;

  p {
    font-size: responsive 16px 20px;
    line-height: responsive 20px 32px;
  }
  h3 {
    font-size: responsive 20px 28px;
    line-height: responsive 24px 32px;
    font-weight: 500;
  }
  strong:nth-of-type(1) {
    color: #78be20;
  }

  strong:nth-of-type(2) {
    color: #007672;
  }
  strong:nth-of-type(3) {
    color: #00a5b6;
  }
}
.intro-center {
  p {
    font-size: responsive 20px 35px;
    line-height: responsive 25px 40px;
  }
}
.page-nav {
  a {
    text-decoration: none;
    display: block;
    padding: 3px 0;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    position: relative;
    font-size: 16px;
  }
  a:hover {
    color: #00a5b6;
  }
}

.drop-inner-nav {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 20px;
    width: 0px;
    height: 0px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
  }
}
.map-bullet {
  ul {
    font-size: 14px;
    margin-left: 10px;
    li {
      list-style-type: circle;
    }
  }
}
.back-to-top {
  position: fixed;
  bottom: 100px;
  right: 40px;
  z-index: 100;
  cursor: pointer;
}

.popup-form {
  position: fixed;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 500;
}
.white-bg {
  background: white;
}
.drop-lang {
  opacity: 0;
}
.drop-lang.open {
  opacity: 1;
}

//Chrome, Safari, Opera
.asx-head ::-webkit-scrollbar {
  width: 0 !important;
}

//Firefox
.asx-head {
  overflow: -moz-scrollbars-none;
}

//Internet Explorer, Edge
.asx-head {
  -ms-overflow-style: none;
}
